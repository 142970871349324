import { getAccessToken, getUserData } from '../libs/auth'
// eslint-disable-next-line no-unused-vars
export default function auth({ next, to }) {
  if (!getAccessToken()) {
    return next({
      name: 'login',
    })
  }
  if (to.meta.roles) {
    if (to.meta.roles.includes(getUserData().userData.role.name)) {
      return next()
    }
    // eslint-disable-next-line no-return-assign
    return window.location = '/405'
  }
  return next()
}
