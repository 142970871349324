export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    CHANGE_LOADING(state, value) {
      state.loading = value
    }
  },
  actions: {
    setLoading({ commit }, value) {
      commit('CHANGE_LOADING', value)
    }
  }
}
