import { getAccessToken } from '../libs/auth'

// eslint-disable-next-line no-unused-vars
export default function guest({ next, store }) {
  if (getAccessToken()) {
    window.location = '/'
    // return next({
    //     name:'home'
    // })
  }
  return next()
}
