
const ACCESS_TOKEN = 'IJRO_ACCESS_TOKEN';
const REFRESH_TOKEN = 'IJRO_REFRESH_TOKEN';
const Ijro_User = 'Ijro_User_Data';
const Ijro_lang = 'Ijro_lang';
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
}

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
}

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
}

export function setUserData(data) {
  return localStorage.setItem(Ijro_User, data);
}
export function getUserData() {
  return JSON.parse(localStorage.getItem(Ijro_User))
}

export function setUserLang(lang) {
  return localStorage.setItem(Ijro_lang, lang);
}
export function getUserLang(){
  return localStorage.getItem(Ijro_lang)
}
export function removeUserLang(){
  return localStorage.removeItem(Ijro_lang);
}