import Vue from 'vue'
import VueRouter from 'vue-router'

import guest from '../middleware/guest'
import auth from '../middleware/auth'
import middlewarePipeline from '../middleware/middlewarePipeline'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/doccategory',
      name: 'pages',
      component: () => import('@/views/loginpages/moderator/components/categorydoc/DocCategory'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Category',
      },
    },
    {
      path: '/doccategory/:id',
      name: 'SubCategory',
      component: () => import('@/views/loginpages/moderator/components/categorydoc/SubCategory'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Category',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        middleware: [auth],
      },
    },
    {
      path: '/documents-by-category/:id',
      name: 'DocumentsByCategory',
      component: () => import('@/views/SecondPage'),
      meta: {
        middleware: [auth],
        roles: ['user','moderator'],
        pageTitle: 'SecondPage',
      },
    },
    {
      path: '/documents/category/:catId/year/:yearId',
      name: 'DocumentsByCategoryAndYear',
      component: () => import('@/views/DocumentByCategoryAndYear'),
      meta: {
        middleware: [auth],
        roles: ['user', 'moderator'],
        pageTitle: 'DocumentByCategoryAndYear',
      },
    },
    {
      path: '/documents/paragpraph/:id',
      name: 'DocumentParagraphByYear',
      component: () => import('@/views/DocumentParagraphByYear'),
      meta: {
        middleware: [auth],
        roles: ['user', 'moderator'],
        pageTitle: 'DocumentParagraphByYear',
      },
    },
    {
      path: '/documents-by-year/:id/paragraph/history/:paragraphId',
      name: 'documentsParagraphHistory',
      component: () => import('@/views/ParagraphHistory'),
      meta: {
        middleware: [auth],
        roles: ['moderator','user'],
        pageTitle: 'Paragraph',
      },
    },
    {
      path: '/completed/:id ',
      name: 'completed',
      component: () => import('@/views/Completed'),
      meta: {
        middleware: [auth],
        roles: ['user'],
        pageTitle: 'УП-6195',
      },
    },
    {
      path: '/punkt-one',
      name: 'punktone',
      component: () => import('@/views/PunktOne'),
      meta: {
        middleware: [auth],
        roles: ['user'],
        pageTitle: 'Пункт 1',
      },
    },
    {
      path: '/subcompleted',
      name: 'subcompleted',
      component: () => import('@/views/SubCompleted'),
      meta: {
        middleware: [auth],
        roles: ['user'],
        pageTitle: 'УП-6195',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '/doccategory',
      name: 'doccategory',
      component: () => import('@/views/loginpages/moderator/components/categorydoc/DocCategory'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'DocCategory',
      },
    },
    {
      path: '/directory',
      name: 'directory',
      component: () => import('@/views/loginpages/moderator/components/mnotypes/directory/Directory'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Directory',
      },
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/loginpages/moderator/documents/index'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Documents',
      },
    },

    {
      path: '/InDocument/:id',
      name: 'InDocument',
      component: () => import('@/views/loginpages/moderator/components/docaction/InDoc'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'InDocument',
      },
    },
    {
      path: '/document/:id/paragraph',
      name: 'Paragraph',
      component: () => import('@/views/loginpages/moderator/documents/Paragraph.vue'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Paragraph',
      },
    },
    {
      path: '/document/:id/paragraph/history/:paragraphId',
      name: 'paragraphHistory',
      component: () => import('@/views/loginpages/moderator/documents/ParagraphHistory'),
      meta: {
        middleware: [auth],
        roles: ['moderator','user'],
        pageTitle: 'History',
      },
    },
    {
      path: '/mnocentre/:id',
      name: 'mnocentre',
      component: () => import('@/views/loginpages/moderator/components/mnotypes/center/MnoCentre'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'mnocentre',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/loginpages/moderator/users/index'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Users',
      },
    },
    {
      path: '/yearsadd',
      name: 'yearsadd',
      component: () => import('@/views/loginpages/moderator/components/year/YearAdd'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'yearsadd',
      },
    },
    {
      path: '/adddocument',
      name: 'adddocument',
      component: () => import('@/views/loginpages/moderator/components/AddDocument'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'adddocument',
      },
    },
    {
      path: '/years',
      name: 'years',
      component: () => import('@/views/loginpages/moderator/components/year/Years'),
      meta: {
        middleware: [auth],
        roles: ['moderator'],
        pageTitle: 'Years',
      },
    },
    {
      path: '/userpage',
      name: 'userpage',
      component: () => import('@/views/loginpages/user/UserHome.vue'),
      meta: {
        middleware: [auth],
        roles: ['user'],
        pageTitle: 'HomePage',
      },
    },
    {
      path: '/seconduser',
      name: 'seconduser',
      component: () => import('@/views/loginpages/user/SecondUserHome'),
      meta: {
        middleware: [auth],
        roles: ['user'],
        pageTitle: 'CategoryName',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    next()
  }
  const { middleware } = to.meta
  const context = {
    to,
    from,
    next,
    store,
  }
  if (middleware) {
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
